import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ubuntu/flomesh.io-main/src/layouts/Fixed/DocMdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/release/history/0.30.0"
        }}>{`0.30.0`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/release/history/0.22.0-31/"
        }}>{`0.22.0-31`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/release/history/0.10.0-1/"
        }}>{`0.10.0-1`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      